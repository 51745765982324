import React from 'react'
import Layout from '../components/Layout.js'
import { Center } from '@patomation/react-ui-components'


const ReviewSuccess = () => {

  return (
   <Layout>

      <Center style={{textAlign: 'center'}}>

        <h1>
          Thank You
        </h1>

        Your review has been submitted.

      </Center>

   </Layout>
 )

}

export default ReviewSuccess
